import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/typography.css"

const TermsPageCN = () => {
  return (
    <Layout language={`cn`} lanLink={`/terms-of-services/`}>
      <SEO title={`用户协议`}/>
      <div className="article">

        <h1>用户协议</h1>

        <p>色采是一款色彩应用，所有功能的目的在于实现色彩的收集、整理、应用，帮助用户在工作中更高效的使用色彩，享受色彩。
          为使用色采软件（以下简称「色采」）及服务，你应当阅读并遵守本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通和使用某项服务的单独协议，并选择接受或者不接受。</p>

        <h2>1.1 协议范围</h2>
        <p>本协议是你与色采之间关于你下载、安装、使用、复制本软件所签订的协议</p>

        <h2>1.2 本服务的许可范围</h2>
        <p>1.2.1 色采给予你一项个人的，不可转让的许可，以使用本软件。你可以为非商业目的在单一台设备上安装、使用、运行本软件。</p>
        <p>1.2.2 你可以为使用本软件及服务的目的复制本软件的一个副本，仅用作备份。备份副本必须包含原软件中含有的所有著作权信息。</p>
        <p>1.2.3 本条及本协议其它条款未明示授权的其它一切权利仍由色采保留，你在行驶这些权利时须另外取得色采的书面许可。色采如果未行驶前述任何权利，并不构成对该权利的放弃。</p>

        <h2>1.3 软件安装、卸载与更新</h2>
        <p>1.3.1 色采可能为不同的终端设备开发了不同版本，你应当根据实际情况选择符合的版本安装。并且，下载安装程序后，你需要按照标准安装程序正确安装。</p>
        <p>1.3.2 如果你不再需要使用本软件或者需要安装新版本，可以自行卸载。</p>
        <p>1.3.3 为了改善用户体验、完善服务内容，色采将不断开发新的服务，并为你不断提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）</p>

        <h2>1.4 会员订阅服务条款</h2>
        <p>欢迎你使用会员订阅服务！</p>
        <p>为使用会员订阅服务（以下简称 "会员订阅服务"），你应当阅读并遵守《会员订阅服务条款》（以下简称 "本条款"）。</p>
        <p>本条款为的特别服务条款。请在接受使用会员订阅服务前务必审慎阅读、充分理解本条款的相应内容，并选择接受或不接受。与本条款有冲突的，以本条款为准。</p>
        <p>领取或经付费开启会员订阅服务后，你可以在相应服务期限内持续访问使用「色采」内所有功能服务。</p>

        <h3>1.4.1 按月、按年订购服务（仅限 iOS）</h3>
        <ol>
          <li>开启按月、按年服务后，你所获得的服务期限分别为31个自然日、365个自然日。</li>
          <li>如你开通按月、按年服务后，则视为你授权「色采」在你已开通的会员订阅服务即将到期时，从你的自有充值账户、与微信读书帐号绑定的第三方支付账户、银行卡、通讯账号、iTunes账户（简称 "账户"）等代扣下一个计费周期的月卡服务费用。扣费时间为计费周期到期前24小时内。</li>
          <li>你需要将「色采」帐号与前述账户进行绑定，且保证有足够余额支持代扣。因上述账户余额不足导致续费失败的，你将自行承担相应结果。</li>
          <li>你可通过下述途径取消已开通的连续包月服务：
            <ol>
              <li>苹果手机在 iOS 设备上，选择「设置」→ 进入「App Store → 点击 「查看 Apple ID」 → 在账户设置页面点击「订阅」 → 取消订阅。</li>
              <li>访问<a href="https://support.apple.com/zh-cn/HT202039">苹果官网帮助页面</a>，点击「管理您的订阅」按钮。</li>
            </ol>
          </li>
        </ol>

        <h3>1.4.2 永久购买</h3>
        <p>如果你开通了永久购买，即代表你所获得的服务期限为无限期，即永久享受「色采」的本地高级版本功能服务。该条款仅限 iOS 端和安卓端的本地高级版本功能，不包括网页端与跨平台的数据同步功能。</p>

        <h2>1.5 付费服务</h2>
        <p>1.5.1 你理解并同意，会员订阅服务为付费服务，「色采」将收取互联网增值服务使用费；你可以在相关服务页面查阅会员订阅服务具体期限及对应费用并自行选择服务的种类。开启会员订阅功能后，无论你是否在相应服务期限内实际使用该服务或访问电子内容，已支付的费用不支持退款。</p>
        <p>1.5.2 你理解并同意，福州台江区求实贸易有限公司有权自行决定并不时修订会员订阅服务相关的收费标准和规则，该类标准及规则一经发布即生效，并构成本条款的有效组成部分。如你不同意前述标准、规则及其修订，你有权停止使用会员订阅服务。你继续使用会员订阅服务即视为你同意相关内容。</p>

        <p>更新时间：2020.01.06</p>
        <p>福州台江区求实贸易有限公司</p>
      </div>
    </Layout>
  )
}

export default TermsPageCN
